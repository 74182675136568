import React, { useState } from "react"
import {
  ButtonBox,
  DialogBox,
  DialogSubtitle,
  DialogText,
  StyledDialog,
} from "./styles"
import { Divider } from "@mui/material"
import { useAppDispatch } from "../../redux/configureStore"
import { Button } from "../Button"
import TextField from "../TextField"
import Label from "../Label"
import { LoadingStatuses } from "../../redux/guest/validateNomaEmail"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/rootReducer"
import CircularProgress from "@mui/material/CircularProgress"
import { selectMember } from "../../redux/members"
import { createFirm } from "../../redux/account"
import { useAuth } from "../../providers/AuthProvider"

type Props = {
  open: boolean
  setOpen: (value: boolean) => void
}

const View = ({ open, setOpen }: Props) => {
  const dispatch = useAppDispatch()
  const member = useSelector(selectMember)
  const [companyName, setCompanyName] = useState("")
  const [error] = useState("")
  const auth = useAuth()
  const { status } = useSelector((state: RootState) => state.validateNomaEmail)

  const handleSubmit = async () => {
    await dispatch(
      createFirm({
        auth: auth,
        property_id: 1,
        organization: companyName,
        isUserAdmin: true,
      })
    )
    setOpen(false)
  }

  if (
    member &&
    member.groupAccounts &&
    member.groupAccounts.length > 0 &&
    !member.groupAccounts[0].admin
  ) {
    return (
      <StyledDialog maxWidth={"lg"} open={open}>
        <DialogBox>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingRight: "30px",
              paddingLeft: "30px",
              gap: "32px",
            }}
          >
            <DialogSubtitle>You are not a company admin!</DialogSubtitle>
            <Divider />
            <DialogText>
              <span>
                If you need a new license please contact your company admin, or
                contact support.
              </span>
            </DialogText>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "24px",
              }}
            ></div>
          </div>
        </DialogBox>
      </StyledDialog>
    )
  }

  return (
    <StyledDialog maxWidth={"lg"} open={open}>
      <DialogBox>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingRight: "30px",
            paddingLeft: "30px",
            gap: "32px",
          }}
        >
          <DialogSubtitle>Create your company</DialogSubtitle>
          <Divider />
          <DialogText>
            <span>Please take a moment to share your company name.</span>
          </DialogText>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "16px",
              }}
            >
              <TextField
                label={<Label text="Company Name" required={true} />}
                name={"companyName"}
                variant={"filled"}
                placeholder="Example L.L.C"
                fullWidth={true}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
            <ButtonBox>
              {error && <div style={{ color: "red" }}>{error}</div>}
              {status === LoadingStatuses.Loading ? (
                <CircularProgress />
              ) : (
                <Button
                  onClick={handleSubmit}
                  disabled={!companyName}
                  children={"Submit"}
                  color={"primary"}
                  size={"medium"}
                />
              )}
            </ButtonBox>
          </div>
        </div>
      </DialogBox>
    </StyledDialog>
  )
}

export default View
